const colors = {
  accent: {
    _light: "#77AAB3",
    _dark: "#AAC9D4",
  },
  background: {
    _light: "#FDFDFE",
    _dark: "#202231",
  },
  surface: {
    _light: "#D8DAE4",
    _dark: "#363847",
  },
  "surface-subtle": {
    _light: "gray.100",
    _dark: "#272938",
  },
  border: {
    _light: "#8388AD",
    _dark: "gray.400",
  },
  "border-strong": {
    _light: "gray.800",
    _dark: "gray.300",
  },
  "border-subtle": {
    _light: "gray.500",
    _dark: "gray.600",
  },
  "border-hover": {
    _light: "gray.900",
    _dark: "gray.50",
  },
  text: {
    _light: "#5F6488",
    _dark: "#d3d3d8",
  },
  "text-weak": {
    _light: "#8388AD",
    _dark: "#6B6D7C",
  },
  "text-weaker": {
    _light: "gray.600",
    _dark: "gray.500",
  },
  "text-weakest": {
    _light: "gray.500",
    _dark: "gray.600",
  },
  "text-link": {
    _light: "gray.700",
    _dark: "gray.400",
  },
  "text-strong": {
    _light: "#4E5377",
    _dark: "#DEE0EF",
  },
};

export const semanticTokens = { colors };
