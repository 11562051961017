import { baseTheme, extendTheme } from "@chakra-ui/react";
import { semanticTokens } from "./semanticTokens";

const styles = {
  global: {
    html: {
      scrollBehavior: "smooth",
    },
    body: {
      backgroundColor: "background",
    },
  },
};

const fonts = {
  body: `"Inter", sans-serif`,
  heading: `"Inter", sans-serif`,
};

const colors = {};

const theme = extendTheme({ semanticTokens, styles, fonts, colors });

export { theme, baseTheme };
export default theme;
